import store from "@/store";
import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from "vuex-module-decorators";
import { CatalogPageData } from "@/utilities/cmsUtilities";

@Module({
  namespaced: true,
  name: "catalogFilterStore",
  store,
  dynamic: true
})

class CatalogFilterStore extends VuexModule {

  inititalCatalogPageData: CatalogPageData = {
    results: [],
    currentItems: [],
    currentChips: [],
    filterString: '',
    hasFilters: false,
    facets: {},
    selectedFacets: [],
    params: '',
    filtersOpen: false,
    queryFacets: [],
    constantFacets: [],
    loading: true,
    searchQuery: '',
    currentPage: 0,
    totalPages: 1,
    totalItems: 0
  };

  catalogPageData: CatalogPageData = this.inititalCatalogPageData;

  get getCatalogPageData() {
    return this.catalogPageData;
  }

  @Mutation
  resetCatalogFilterData() {
    this.catalogPageData.selectedFacets = [];
    this.catalogPageData.currentChips = [];
    this.catalogPageData.filterString = '';
    this.catalogPageData.hasFilters = false;
    this.catalogPageData.facets = {};
    this.catalogPageData.queryFacets = [];
    this.catalogPageData.searchQuery = "";
  }

  @Action({ commit: 'resetCatalogFilterData' })
  resetCatalogFilterDataAction() {
    this.context.commit('resetCatalogFilterData');
    console.log("resetCatalogFilterDataAction");
  }
}
export default getModule(CatalogFilterStore);